exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3RunkmSYQDNoLYnjGvg-o1 {\n  width: 100%;\n  font-size: 24px;\n}\n\n._2qqSnMmOCP9ozs19gsMelV {\n  font-family: Roboto !important;\n  font-size: 14px;\n  letter-spacing: 0.5px;\n  color: black;\n  font-weight: bolder;\n}\n\n._3TojjajEJME3aqaRrN_3rj {\n  margin-top: 0.3rem;\n  cursor: pointer;\n  font-weight: bold;\n}\n\n._3TojjajEJME3aqaRrN_3rj:hover {\n  color: #3d414f;\n}\n\n._2AmokQWZc_Hbj9HAQdPXHk {\n  margin-left: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"platformInfo-div": "_3RunkmSYQDNoLYnjGvg-o1",
	"download-pdf-div": "_2qqSnMmOCP9ozs19gsMelV",
	"download-pdf-item": "_3TojjajEJME3aqaRrN_3rj",
	"pdf-name": "_2AmokQWZc_Hbj9HAQdPXHk"
};