import { fromBinary, getCookie } from '../common';
import { APIGatewayPrefix, ServerAPIPrefix } from '../config/config';
import { getDefaultOptions, returnResponse } from './apiConfig';
import { api_options_get } from './apiOptions';

export const httpEditUploadedDocumentDescription = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/document/${payload.id}/description?username=${payload.username}`;

  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify({
      description: payload.description,
    }),
  });

  return returnResponse(response);
};

export const httpUpdateLlmModelForTopic = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${payload.topicId}/edit`;

  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify({
      llm_server: payload.llmModel,
      responce_style: payload.responceStyle,
    }),
  });

  return returnResponse(response);
};

export const httpGetChatReference = async (payload) => {
  const options = getDefaultOptions();

  let url = `${ServerAPIPrefix}chatgpt/chat/reference/${payload.session_id}/${payload.pair_chat_id}`;

  if (payload.doc_idx) {
    url = url + `?doc_idx=${payload.doc_idx}`;
  }

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpCleanChatByTopicId = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${payload.topicId}/clean_chat`;

  const response = await fetch(url, {
    ...options,
    method: 'PUT',
  });

  return returnResponse(response);
};

export const httpResetChat = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat/eoc/${payload.session_id}`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
  });

  return returnResponse(response);
};

export const httpDeleteUploadedDocumentInfo = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/document/${payload.documentId}`;

  const response = await fetch(url, {
    ...options,
    method: 'DELETE',
  });

  return returnResponse(response);
};

export const httpDeleteDossier = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/dossier/${payload.dossierId}?username=${payload.username}`;

  const response = await fetch(url, {
    ...options,
    method: 'DELETE',
  });

  return returnResponse(response);
};

export const httpDeleteUploadedDocumentInfoList = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/document/delete`;

  const response = await fetch(url, {
    ...options,
    method: 'DELETE',
    body: JSON.stringify({
      id: payload.document_id,
    }),
  });

  return returnResponse(response);
};

export const httpDownloadUploadedDocument = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/document/download`;

  let filename;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      id: payload.document_id,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      filename = response.headers
        .get('Content-Disposition')
        .split('filename=')[1];
      return response.blob();
    })
    .then((blob) => {
      if (blob != null) {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    });

  return { status: 'success' };
  // return returnResponse(response);
};

export const httpEditTopic = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${payload.topicId}/edit?in_topic=${payload.inTopic}`;

  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify({
      topic_name: payload.topicName,
      doc_list: payload.docList,
      description: payload.description,
      dossier_list: payload.dossierList,
    }),
  });

  return returnResponse(response, true);
};

export const httpCreateTopic = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/chat_topic/create`;
  const apiPayload = {
    chat_type: payload.chatType,
    topic_name: payload.topicName,
    user_name: payload.userName,
    doc_list: payload.docList,
    dossier_list: payload.dossierList,
    description: payload.description,
    delete_lru: payload.delete_lru,
    status: payload.status,
    llm_server: payload.llm_server,
  };

  if (payload.assistant) {
    apiPayload.assistant = payload.assistant;
  }

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(apiPayload),
  });

  return returnResponse(response, true);
};

export const httpGetTopicInfo = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${payload.topicId}/edit`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpGetUploadedDocumentInfo = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/document/doc_list?username=${payload.userName}`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpUploadDocument = async (payload, timeout) => {
  const options = getDefaultOptions();

  let url;
  if ('topicId' in payload) {
    url = `${ServerAPIPrefix}chatgpt/document/upload?username=${payload.userName}&overwrite=${payload.overwrite}&topic_id=${payload.topicId}`;
  } else {
    url = `${ServerAPIPrefix}chatgpt/document/upload?username=${payload.userName}&overwrite=${payload.overwrite}`;
  }

  if (timeout) {
    url = url + `&timeout=${timeout}`;
  }

  const original_user_info = getCookie('original_user_info');
  const username = original_user_info
    ? JSON.parse(fromBinary(atob(original_user_info))).name
    : '';

  const formData = new FormData();

  for (let i = 0; i < payload.file.length; i++) {
    formData.append('name', payload.file[i].name);
    formData.append('documents', payload.file[i]);
  }

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${username}`,
    },
    body: formData,
  });

  return returnResponse(response);
};

export const httpDeleteTopicInfo = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${payload.topic_id}`;

  const response = await fetch(url, {
    ...options,
    method: 'DELETE',
  });

  return returnResponse(response);
};

export const httpGetTopic = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${payload.chatTopic}/topic_list?username=${payload.userName}&simple=${payload.simple}`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpGetLastChatTopic = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${payload.chatType}/last?username=${payload.userName}`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpGetChatTopicInfo = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${payload.topicId}`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpGetHistoricalChatByTopicId = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${payload.topicId}/historical_chat`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpGetUploadedDocumentInfoByTopicId = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${payload.topicId}/doc_list`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpGetDossierInfoByUsername = async (payload) => {
  const options = getDefaultOptions();

  let url = `${ServerAPIPrefix}chatgpt/dossier/list?username=${payload.username}`;

  if (payload.public !== undefined) {
    url += `&public=${payload.public}`;
  }

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpGetDossierInfoByChatId = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${payload.chatId}/dossier_list`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpGetSharedChatUserListByChatId = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${payload.topicId}/share`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpUpdateChatUserList = async (payload, topicId) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${topicId}/share`;

  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  return returnResponse(response);
};

export const httpGetDossierDocumentInfo = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/dossier/${payload.dossierId}/documents?username=${payload.username}`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpUpdateDossier = async (payload, timeout) => {
  const options = getDefaultOptions();
  let url = `${ServerAPIPrefix}chatgpt/dossier/${payload.dossier_id}?username=${payload.username}`;

  if (timeout) {
    url = url + `&timeout=${timeout}`;
  }

  const original_user_info = getCookie('original_user_info');
  const username = original_user_info
    ? JSON.parse(fromBinary(atob(original_user_info))).name
    : '';

  const formData = new FormData();

  for (let i = 0; i < payload.file_list.length; i++) {
    formData.append('name', payload.file_list[i].name);
    formData.append('documents', payload.file_list[i]);
  }

  formData.append(
    'dossier_info',
    JSON.stringify({
      name: payload.name,
      doc_list: payload.doc_list,
      description: payload.description,
      public: payload.public,
      user_list: payload.user_list,
    }),
  );

  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: formData,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${username}`,
    },
  });

  return returnResponse(response);
};

export const httpCreateDossier = async (payload, timeout) => {
  const options = getDefaultOptions();
  let url = `${ServerAPIPrefix}chatgpt/dossier/create?username=${payload.username}`;
  if (payload.chat_id) {
    url += `&chat_id=${payload.chat_id}`;
  }

  if (timeout) {
    url = url + `&timeout=${timeout}`;
  }

  const original_user_info = getCookie('original_user_info');
  const username = original_user_info
    ? JSON.parse(fromBinary(atob(original_user_info))).name
    : '';

  const formData = new FormData();

  for (let i = 0; i < payload.file_list.length; i++) {
    formData.append('name', payload.file_list[i].name);
    formData.append('documents', payload.file_list[i]);
  }

  formData.append(
    'dossier_info',
    JSON.stringify({
      name: payload.name,
      doc_list: payload.doc_list,
      description: payload.description,
      public: payload.public,
      user_list: payload.user_list,
    }),
  );

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${username}`,
    },
  });

  return returnResponse(response);
};

export const httpGetLLMModel = async () => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/llm_server/server_list`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpGetSetting = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/config/chat/${payload.chatType}?username=${payload.username}`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpGetEmbeddedTopic = async () => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/embedding/topic_list`;

  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response);
};

export const httpCreateEmbeddedTopicChat = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/embedding/topic`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      topic_name: payload.topic_name,
      content: payload.content,
    }),
  });
  return returnResponse(response);
};

export const httpSendFeedback = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/chat/comment`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      type: payload.type,
      user_name: payload.userName,
      session_id: payload.sessionId,
      pair_chat_id: payload.pairChatId,
      action: payload.action,
      user_comment: payload.feedback,
      optional_comment: payload.optionalComment,
    }),
  });
  return returnResponse(response);
};

export const httpGetOptionComment = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat/optional_comment/${payload.commentType}`;

  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response);
};

export const httpSendChat = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat`;
  const data = {
    current_message: payload.message,
    user_name: payload.username,
    type: payload.chatType,
    llm_server: payload.model || '',
    chat_id: payload.chatId,
  };

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(data),
  });
  return returnResponse(response, true);
};

export const httpGetDatabaseTopic = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/topics/text_to_sql/${payload.userName}`;

  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response);
};

export const httpGetDatabaseInfo = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/topic_info/text_to_sql/${payload.topic}`;

  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response);
};

export const httpGetPrompt = async (chatId) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/prompt?chat_id=${chatId}`;
  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response);
};

export const httpCreatePrompt = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/prompt`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      author: atob(getCookie('user')),
      prompt_name: payload.name,
      prompt_description: payload.description,
      prompt: payload.content,
      chat_id: payload.chatId,
    }),
  });

  return returnResponse(response, true);
};

export const httpUpdatePrompt = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/prompt`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify({
      prompt_id: payload.id,
      prompt_name: payload.name,
      prompt_description: payload.description,
      prompt: payload.content,
    }),
  });

  return returnResponse(response, true);
};

export const httpDeletePrompt = async (promptId, chatId) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/prompt?prompt_id=${promptId}&chat_id=${chatId}`;
  const response = await fetch(url, {
    ...options,
    method: 'DELETE',
  });
  return returnResponse(response, true);
};

export const httpGetAssistants = async () => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/config/assistant`;
  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response, true);
};

export const httpGetAssistant = async (id, isDetail) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/assistant/${id}?detail=${isDetail}`;
  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response);
};

export const httpGetAllAssistants = async ({ isPublic, username }) => {
  const options = getDefaultOptions();
  let url = `${ServerAPIPrefix}chatgpt/assistant?`;
  if (isPublic) {
    url += 'public=true';
    if (username) {
      url += `&&author=${username}`;
    }
  } else {
    if (username) {
      url += `author=${username}`;
    }
  }

  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response, true);
};

export const httpCreateAssistants = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/assistant`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return returnResponse(response, true);
};

export const httpUpdateAssistants = async (payload, id) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/assistant?assistant_id=${id}`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  return returnResponse(response, true);
};

export const httpDeleteAssistants = async (id, realDelete = false) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/assistant/${id}?real_delete=${realDelete}`;
  const response = await fetch(url, {
    ...options,
    method: 'DELETE',
  });
  return returnResponse(response, true);
};

export const httpUpdateChatConfig = async (topicId, payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${topicId}/edit?in_topic=true`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  return returnResponse(response, true);
};

export const httpToggleChatPin = async (topicId, isPin) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${topicId}/pin?pin=${isPin}`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
  });

  return returnResponse(response);
};

export const httpGetPlugins = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/plugin/?public=${payload.public}`;
  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response);
};

export const httpStreamResponse = async (payload, controller) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatstream/chat`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    signal: controller?.signal,
    body: JSON.stringify(payload),
  });

  return returnResponse(response, true);
};

export const httpValidateToolProperty = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/plugin/validate_tool_property`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  return returnResponse(response, true);
};

export const httpValidateAuthToken = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/plugin/validate_auth_token`;
  const reqBody = {
    name: payload.name,
    api_spec: payload.api_spec,
    auth: {
      type: payload.auth.type,
      token: payload.auth.token,
      verify_url: payload.auth.verify_url,
      method: payload.auth.method,
    },
  };
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(reqBody),
  });

  return returnResponse(response, true);
};

export const httpStopConversation = async (message_id, message_role) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/chat/stop`;
  const reqBody = {
    message_id,
    message_role,
  };
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(reqBody),
  });

  return returnResponse(response, true);
};

export const httpResetConversation = async (chatID) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/chat_topic/${chatID}/clean_chat`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
  });

  return returnResponse(response, true);
};

export const httpGetAvailablePlugins = async () => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/plugin/available_plugins`;
  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response);
};

export const httpGetOriginalImage = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/chat/file/${payload.session_id}/${payload.pair_chat_id}/${payload.file_num}`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpPostDownloadChatToFile = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/chat_topic/download_chat_to_file?topic_id=${payload.topic_id}&username=${payload.username}&file_format=${payload.file_format}`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
  });

  return response;
};

export const httpGetTranslateSupportLanguage = async () => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/document/translate_support_language`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
};

export const httpPostDocumentTranslate = async (payload) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}chatgpt/document/translate?source_lang=${payload.source_lang}&target_lang=${payload.target_lang}&llm_name=${payload.llm_name}`;

  const original_user_info = getCookie('original_user_info');
  const username = original_user_info
    ? JSON.parse(fromBinary(atob(original_user_info))).name
    : '';

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: payload.formData,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${username}`,
    },
  });

  return response;
};

export const httpPostSTT = async (payload) => {
  const options = api_options_get();

  const url = `${APIGatewayPrefix}/aimodel/stt`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    headers: {
      ...options.headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      content: payload.audioBase64,
      media_type: 'audio/mp3',
      blocking: payload.blocking,
    }),
  });

  return response;
};

export const httpGetUserGuide = async (payload) => {
  const options = getDefaultOptions();

  let url = `${ServerAPIPrefix}chatgpt/userguide/download`;

  if (payload.internal) {
    url += `?internal=${payload.internal}`;
  }
  const response = await fetch(url, {
    ...options,
  });

  return response;
};
