import React, { useState } from 'react';
import { usePureStoreSelector } from '../../store/pureStore';
import { getCambrianFileServer } from '../../common';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getSystemConfig } from '../../utils/common_utils';
import moment from 'moment';
import styles from './PlatformInfoModal.module.css';
import { httpGetUserGuide } from '../../api/chat';

const convertExpireDate = (expireDate) => {
  const formatDate = 'YYYY-MM-DD HH:mm:ss';
  if (!isNaN(parseInt(expireDate))) {
    return moment.unix(expireDate).format(formatDate);
  } else {
    return expireDate;
  }
};

const licenseExpireDays = (strDate) => {
  if (!strDate) {
    return '0 day';
  }

  let targetDate = null;

  if (!isNaN(parseInt(strDate))) {
    targetDate = new Date(parseInt(strDate) * 1000);
  } else {
    targetDate = new Date(strDate);
  }

  const currentDate = new Date();
  const timeDiff = targetDate.getTime() - currentDate.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if (daysDiff < 1) {
    return '0 day';
  } else if (daysDiff === 1) {
    return '1 day';
  }

  return daysDiff + ' days';
};

const PlatformInfoModal = ({ t, isLystroPage, closeModal }) => {
  const enableAIAssistant =
    window.ENABLE_CHAT &&
    window.ENABLE_CHAT.toString().toLowerCase() === 'true';

  const licenseInfo = usePureStoreSelector(
    (state) => state.license.licenseInfo,
  );
  const [isDownloading, setIsDownloading] = useState(false);

  const handleLystroDownload = (fileName) => {
    const link = document.createElement('a');
    link.href = `${getCambrianFileServer()}/doc/${fileName}`;
    link.download = fileName;
    link.click();
  };

  const handleAIAssistantDownload = async () => {
    if (isDownloading) {
      return;
    }

    const isInternal = !(
      (window.IS_AZURE &&
        window.IS_AZURE.toString().toLowerCase() === 'true') ||
      (window.ENABLE_AI_ASSISTANT_STANDALONE &&
        window.ENABLE_AI_ASSISTANT_STANDALONE.toString().toLowerCase() ===
          'true')
    );

    try {
      setIsDownloading(true);
      const response = await httpGetUserGuide({ internal: isInternal });
      const contentDisposition = response.headers.get('Content-Disposition');
      let filename = `AI_Assistant_User_Guide.pdf`;
      if (contentDisposition) {
        const filenameRegex = /filename\*?=(?:UTF-8'')?([^;]*)/i;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches && matches[1]) {
          filename = decodeURIComponent(matches[1].replace(/"/g, ''));
        }
      }
      const blob = await response.blob();
      if (blob != null) {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([blob]));
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    } catch (error) {
      console.error(error);
      toastr.error(t('platform_info.download_failed'));
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Modal
      show={true}
      onHide={closeModal}
      backdrop='static'
      dialogClassName='modal--default modal--sl'
      aria-labelledby='modal_reset_password'
    >
      <Modal.Header closeButton>
        <Modal.Title id='modal_reset_password'>
          {t('platform_info.platform_info')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles['platformInfo-div']}>
          <div className='form-group'>
            <label>{`COMMIT SHA : ${window.CURRENT_COMMIT_SHA}`}</label>
          </div>
          <div className='form-group'>
            <label>{`${t('platform_info.version_tag')} : ${window.CURRENT_COMMIT_TAG}`}</label>
          </div>
          <div className='form-group'>
            <label>{`${t('platform_info.deploy_date')} : ${window.CURRENT_DEPLOY_DATE}`}</label>
          </div>
          {(isLystroPage || enableAIAssistant) && (
            <div className='form-group'>
              <label>{`${t('platform_info.download_pdfs')} :`}</label>
              <div className={styles['download-pdf-div']}>
                {isLystroPage && (
                  <>
                    <div
                      className={styles['download-pdf-item']}
                      onClick={() =>
                        handleLystroDownload('Kinsus_labeling_manual.pdf')
                      }
                    >
                      <FontAwesomeIcon icon={solid('download')} />
                      <span className={styles['pdf-name']}>
                        Kinsus Labeling Manual
                      </span>
                    </div>
                    <div
                      className={styles['download-pdf-item']}
                      onClick={() =>
                        handleLystroDownload('Lystro_API_spec.pdf')
                      }
                    >
                      <FontAwesomeIcon icon={solid('download')} />
                      <span className={styles['pdf-name']}>
                        Lystro API Spec
                      </span>
                    </div>
                    <div
                      className={styles['download-pdf-item']}
                      onClick={() =>
                        handleLystroDownload('PEGAAI_basic_user_guide.pdf')
                      }
                    >
                      <FontAwesomeIcon icon={solid('download')} />
                      <span className={styles['pdf-name']}>
                        PEGAAI Basic User Guide
                      </span>
                    </div>
                  </>
                )}
                {enableAIAssistant && (
                  <div
                    className={styles['download-pdf-item']}
                    onClick={() => handleAIAssistantDownload()}
                  >
                    {isDownloading ? (
                      <FontAwesomeIcon icon={solid('spinner')} spin />
                    ) : (
                      <FontAwesomeIcon icon={solid('download')} />
                    )}

                    <span className={styles['pdf-name']}>
                      {t('platform_info.ai_assistant_user_guide')}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}

          {getSystemConfig('REQUIRED_LICENSE') && (
            <div className='form-group'>
              {licenseInfo &&
              licenseInfo.licenseType &&
              licenseInfo.licenseExpire ? (
                <label>
                  LICENSE : {licenseInfo.licenseType} {t('expired_at')}{' '}
                  {convertExpireDate(licenseInfo.licenseExpire)} (
                  {licenseExpireDays(licenseInfo.licenseExpire)})
                </label>
              ) : (
                <label>LICENSE : -</label>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PlatformInfoModal;
