import React, { forwardRef, useEffect, useReducer } from 'react';
import { Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  checkIsAdmin,
  checkIsGuest,
  checkIsStandardAccount,
  getCookie,
  withRouter,
} from './common';
import { withTranslation } from 'react-i18next';
import { http_password_change, httpResetPassword } from './user_utils';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { checkIsFunctionEnabled, getSystemConfig } from './utils/common_utils';
import PlatformInfoModal from './UserOptMenu/Modal/PlatformInfoModal';

const CONSTANT = {
  OLDPASSWORD: 'OldPassword',
  NEWPASSWORD: 'NewPassword',
  NEWPASSWORDCONFIRM: 'NewPasswordConfirm',
  CHANGEOLDPASSWORD: 'change_old_password',
  CHANGENEWPASSWORD: 'change_new_password',
  CHANGENEWPASSWORDCONFIRM: 'change_new_password_confirm',
  SETLOADING: 'set_loading',
  SETFORMVALID: 'set_form_valid',
  SETHTTPERROR: 'set_http_error',
  CHECKOLDPASSWORDVALID: 'check_old_password_valid',
  CHECKNEWPASSWORDVALID: 'check_new_password_valid',
  CHECKPASSWORDCONFIRMVALID: 'check_password_confirm_valid',
};

const formAction = () => {
  const changeOldPassword = (value) => {
    return { type: CONSTANT.CHANGEOLDPASSWORD, value };
  };

  const changeNewPassword = (value) => {
    return { type: CONSTANT.CHANGENEWPASSWORD, value };
  };

  const changeNewPasswordConfirm = (value) => {
    return { type: CONSTANT.CHANGENEWPASSWORDCONFIRM, value };
  };

  const setLoading = (value) => {
    return { type: CONSTANT.SETLOADING, value };
  };

  const setFormValid = (value) => {
    return { type: CONSTANT.SETFORMVALID, value };
  };

  const setHttpError = (value) => {
    return { type: CONSTANT.setHttpError, value };
  };

  const checkOldPasswordValid = (value) => {
    return { type: CONSTANT.CHECKOLDPASSWORDVALID, value };
  };

  const checkNewPasswordValid = (value) => {
    return { type: CONSTANT.CHECKNEWPASSWORDVALID, value };
  };

  const checkPassowrdConfirmValid = (value) => {
    return { type: CONSTANT.CHECKPASSWORDCONFIRMVALID, value };
  };

  return {
    changeOldPassword,
    changeNewPassword,
    changeNewPasswordConfirm,
    setLoading,
    setFormValid,
    setHttpError,
    checkOldPasswordValid,
    checkNewPasswordValid,
    checkPassowrdConfirmValid,
  };
};

const formReducer = (state, action) => {
  const value = action.value;
  switch (action.type) {
    case CONSTANT.CHANGEOLDPASSWORD: {
      return { ...state, oldPassword: value };
    }
    case CONSTANT.CHANGENEWPASSWORD: {
      return { ...state, newPassword: value };
    }
    case CONSTANT.CHANGENEWPASSWORDCONFIRM: {
      return { ...state, newPasswordConfirm: value };
    }
    case CONSTANT.SETLOADING: {
      return { ...state, isLoading: value };
    }
    case CONSTANT.SETFORMVALID: {
      return { ...state, isValid: value };
    }
    case CONSTANT.setHttpError: {
      return { ...state, httpError: value };
    }
    case CONSTANT.CHECKOLDPASSWORDVALID: {
      if (value === '') {
        return {
          ...state,
          isOldPasswordValid: {
            valid: false,
            error: 'Input can not be empty',
          },
        };
      }

      return {
        ...state,
        isOldPasswordValid: {
          valid: true,
          error: '',
        },
      };
    }
    case CONSTANT.CHECKNEWPASSWORDVALID: {
      if (value === '') {
        return {
          ...state,
          isNewPasswordValid: {
            valid: false,
            error: 'Input can not be empty',
          },
        };
      }

      if (value.length < 8) {
        return {
          ...state,
          isNewPasswordValid: {
            valid: false,
            error: 'Password is too short (at least 8 characters)',
          },
        };
      }

      const reg = /(?=.*[A-Za-z])(?=.*\d).{8,}/;

      if (!reg.test(value)) {
        return {
          ...state,
          isNewPasswordValid: {
            valid: false,
            error:
              'Password is not valid (at least including a number and a letter)',
          },
        };
      }

      return {
        ...state,
        isNewPasswordValid: {
          valid: true,
          error: '',
        },
      };
    }
    case CONSTANT.CHECKPASSWORDCONFIRMVALID: {
      if (value === '') {
        return {
          ...state,
          isPasswordConfirmValid: {
            valid: false,
            error: 'Input can not be empty',
          },
        };
      }

      if (value !== state.newPassword) {
        return {
          ...state,
          isPasswordConfirmValid: {
            valid: false,
            error: 'The Password are not the same',
          },
        };
      }

      return {
        ...state,
        isPasswordConfirmValid: {
          valid: true,
          error: '',
        },
      };
    }
    default: {
      return state;
    }
  }
};

const ChangePasswordModal = (props) => {
  const { t, closeModal, username, is_admin } = props;

  const [state, dispatch] = useReducer(formReducer, {
    isLoading: false,
    httpError: '',
    oldPassword: is_admin ? 'set_password_by_admin' : '',
    newPassword: '',
    newPasswordConfirm: '',
    isValid: false,
    isOldPasswordValid: is_admin ? { valid: true, error: '' } : null,
    isPasswordConfirmValid: null,
    isNewPasswordValid: null,
  });

  const {
    isLoading,
    httpError,
    oldPassword,
    newPassword,
    newPasswordConfirm,
    isValid,
    isOldPasswordValid,
    isNewPasswordValid,
    isPasswordConfirmValid,
  } = state;

  const {
    setLoading,
    setFormValid,
    setHttpError,
    changeOldPassword,
    changeNewPassword,
    changeNewPasswordConfirm,
    checkOldPasswordValid,
    checkNewPasswordValid,
    checkPassowrdConfirmValid,
  } = formAction();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case CONSTANT.OLDPASSWORD: {
        dispatch(changeOldPassword(value));
        dispatch(checkOldPasswordValid(value));
        return;
      }
      case CONSTANT.NEWPASSWORD: {
        dispatch(changeNewPassword(value));
        dispatch(checkNewPasswordValid(value));
        if (newPasswordConfirm) {
          dispatch(checkPassowrdConfirmValid(newPasswordConfirm));
        }
        return;
      }
      case CONSTANT.NEWPASSWORDCONFIRM: {
        dispatch(changeNewPasswordConfirm(value));
        dispatch(checkPassowrdConfirmValid(value));
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleConfirmPassword = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case CONSTANT.OLDPASSWORD: {
        dispatch(checkOldPasswordValid(value));
        return;
      }
      case CONSTANT.NEWPASSWORD: {
        dispatch(checkNewPasswordValid(value));
        if (newPasswordConfirm) {
          dispatch(checkPassowrdConfirmValid(newPasswordConfirm));
        }
        return;
      }
      case CONSTANT.NEWPASSWORDCONFIRM: {
        dispatch(checkPassowrdConfirmValid(value));
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSubmit = () => {
    if (!isValid || isLoading) {
      return;
    }
    dispatch(setLoading(true));

    const payload = {
      user: is_admin ? username : atob(getCookie('user')),
      oldPassword,
      newPassword,
    };

    const handlers = {
      success: function () {
        dispatch(setLoading(false));
        toastr.success('Reset Success!', 'Success');
        closeModal();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log('Error: ', jqXHR.responseJSON.error);
        console.log('Detail: ', jqXHR.responseJSON.detail);
        dispatch(setLoading(false));
        dispatch(setHttpError(jqXHR.responseJSON.detail));
      },
    };

    let http_password_api = http_password_change;
    if (oldPassword.includes('set_password_by_admin'))
      http_password_api = httpResetPassword;

    http_password_api(this, payload, handlers);
  };

  useEffect(() => {
    if (!isOldPasswordValid || !isNewPasswordValid || !isPasswordConfirmValid)
      return;
    const valid =
      isOldPasswordValid.valid &&
      isNewPasswordValid.valid &&
      isPasswordConfirmValid.valid;
    dispatch(setFormValid(valid));
  }, [isOldPasswordValid, isNewPasswordValid, isPasswordConfirmValid]);

  return (
    <Modal
      show={true}
      onHide={closeModal}
      backdrop='static'
      dialogClassName='modal--default modal--sl'
      aria-labelledby='modal_reset_password'
    >
      <Modal.Header closeButton>
        <Modal.Title id='modal_reset_password'>
          {t('user.change_password')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form style={{ width: '100%' }}>
          <div
            className='form-group'
            style={{ display: is_admin ? 'none' : null }}
          >
            <label htmlFor='old_password'>{'Old Password'} *</label>
            <input
              type='password'
              className='form-control'
              id='old_password'
              style={{
                boxShadow:
                  isOldPasswordValid !== null && !isOldPasswordValid.valid
                    ? '0 0 0 0.2rem #e56974'
                    : '',
              }}
              name={CONSTANT.OLDPASSWORD}
              onChange={handleInputChange}
              onBlur={handleConfirmPassword}
              value={oldPassword}
            />
            {isOldPasswordValid !== null && !isOldPasswordValid.valid && (
              <span style={{ color: 'red' }}>{isOldPasswordValid.error}</span>
            )}
          </div>
          <div className='form-group'>
            <label htmlFor='new_password'>{'New Password'} *</label>
            <input
              type='password'
              className='form-control'
              id='new_password'
              style={{
                boxShadow:
                  isNewPasswordValid !== null && !isNewPasswordValid.valid
                    ? '0 0 0 0.2rem #e56974'
                    : '',
              }}
              name={CONSTANT.NEWPASSWORD}
              onChange={handleInputChange}
              onBlur={handleConfirmPassword}
              value={newPassword}
            />
            {isNewPasswordValid !== null && !isNewPasswordValid.valid && (
              <span style={{ color: 'red' }}>{isNewPasswordValid.error}</span>
            )}
          </div>
          <div className='form-group'>
            <label htmlFor='new_password_confirm'>
              {'Confirm New Password'} *
            </label>
            <input
              type='password'
              className='form-control'
              id='new_password_confirm'
              style={{
                boxShadow:
                  isPasswordConfirmValid !== null &&
                  !isPasswordConfirmValid.valid
                    ? '0 0 0 0.2rem #e56974'
                    : '',
              }}
              name={CONSTANT.NEWPASSWORDCONFIRM}
              onChange={handleInputChange}
              onBlur={handleConfirmPassword}
              value={newPasswordConfirm}
            />
            {isPasswordConfirmValid !== null &&
              !isPasswordConfirmValid.valid && (
                <span style={{ color: 'red' }}>
                  {isPasswordConfirmValid.error}
                </span>
              )}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {httpError && (
              <div className='err-msg-span' style={{ marginRight: 16 }}>
                {httpError}
              </div>
            )}
            <button
              disabled={!isValid || isLoading}
              type='button'
              className='btn submit-gray-bule-type btn__outline--dark feature-usage'
              onClick={handleSubmit}
            >
              {isLoading ? (
                <span>
                  <i className='pegafont icon-spin5 animate-spin'></i>&nbsp;
                  {t('button.processing')}
                </span>
              ) : (
                t('button.submit')
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

const CustomToggle = forwardRef(function CustomToggle(props, ref) {
  const username = atob(getCookie('user'));

  const handleClick = (e) => {
    e.preventDefault();
    props.onClick(e);
  };

  return (
    <OverlayTrigger
      placement='right'
      overlay={
        <Tooltip style={{ position: 'fixed' }}>{'Hi, ' + username} </Tooltip>
      }
    >
      <button
        ref={ref}
        className='btn btn-md allowed-without-license'
        onClick={handleClick}
      >
        {props.children}
      </button>
    </OverlayTrigger>
  );
});

class UserOptMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPasswordModal: false,
      openPlatformInfoModal: false,
    };
    this.onSelect = this.onSelect.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toLogout = this.toLogout.bind(this);
  }

  onSelect(key) {
    console.log(key);
  }

  closeModal(modal) {
    this.setState({ [modal]: false });
  }

  toLogout() {
    const { navigate } = this.props;
    localStorage.removeItem('token'); // Remove legacy settings
    navigate('/logout');
  }

  render() {
    const { t, navigate, params } = this.props;
    const isSaronORLystroPage =
      window.location.pathname.startsWith('/smtrejudge');
    const lystroToken = getSystemConfig('LYSTRO_TOKEN');
    const isLystroPage =
      window.location.pathname.startsWith('/smtrejudge') &&
      params?.template_token === lystroToken;

    return (
      <Dropdown
        id='btn-option-menu'
        className='NavbarDpBtn'
        onSelect={this.onSelect}
      >
        <Dropdown.Toggle as={CustomToggle}>
          <FontAwesomeIcon
            icon={solid('user')}
            style={{ fontSize: '16px', color: 'white' }}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {isSaronORLystroPage ? (
            <>
              {checkIsStandardAccount() && (
                <>
                  <Dropdown.Item
                    className='NavbarDpBtn-link'
                    onClick={() => this.setState({ resetPasswordModal: true })}
                  >
                    {t('user.change_password')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  {this.state.resetPasswordModal && (
                    <ChangePasswordModal
                      t={t}
                      closeModal={() => this.closeModal('resetPasswordModal')}
                    />
                  )}
                </>
              )}
              <Dropdown.Item
                onClick={() => this.setState({ openPlatformInfoModal: true })}
                className='NavbarDpBtn-link allowed-without-license'
              >
                {t('button.about')}
              </Dropdown.Item>
              {this.state.openPlatformInfoModal && (
                <PlatformInfoModal
                  t={t}
                  isLystroPage={isLystroPage}
                  closeModal={() => this.closeModal('openPlatformInfoModal')}
                />
              )}
              <Dropdown.Divider />
              <Dropdown.Item
                className='NavbarDpBtn-link allowed-without-license'
                onClick={this.toLogout}
              >
                {t('user.logout')}
              </Dropdown.Item>
            </>
          ) : (
            <>
              {checkIsAdmin() && (
                <>
                  <Dropdown.Item
                    as={Link}
                    to={'/backend'}
                    className='NavbarDpBtn-link'
                  >
                    {t('user.backend')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </>
              )}
              {checkIsStandardAccount() && (
                <>
                  <Dropdown.Item
                    className='NavbarDpBtn-link'
                    onClick={() => this.setState({ resetPasswordModal: true })}
                  >
                    {t('user.change_password')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  {this.state.resetPasswordModal && (
                    <ChangePasswordModal
                      t={t}
                      closeModal={() => this.closeModal('resetPasswordModal')}
                    />
                  )}
                </>
              )}
              {window.ENABLE_GROUP &&
                !checkIsGuest() &&
                checkIsFunctionEnabled('ENABLE_SUBSCRIPTION') &&
                window.ENABLE_AI_ASSISTANT_STANDALONE === 'false' &&
                !getSystemConfig('HIDE_GROUP') && (
                  <>
                    <Dropdown.Item
                      as={Link}
                      to={'/subscription'}
                      className='NavbarDpBtn-link'
                    >
                      {t('user.subscription')}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                )}
              {checkIsFunctionEnabled('ENABLE_PROMOTIONAL_VIDEOS') &&
                window.ENABLE_AI_ASSISTANT_STANDALONE === 'false' && (
                  <>
                    <Dropdown.Item
                      as={Link}
                      to={'./promo_videos'}
                      className='NavbarDpBtn-link'
                    >
                      {t('common.promotional_videos')}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                )}
              {window.AUTH_URL && (
                <>
                  <Dropdown.Item
                    as={Link}
                    to={'./user_settings'}
                    className='NavbarDpBtn-link'
                  >
                    {t('user.user_settings')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </>
              )}
              <>
                <Dropdown.Item
                  onClick={() => this.setState({ openPlatformInfoModal: true })}
                  className='NavbarDpBtn-link allowed-without-license'
                >
                  {t('button.about')}
                </Dropdown.Item>
                <Dropdown.Divider />
                {this.state.openPlatformInfoModal && (
                  <PlatformInfoModal
                    t={t}
                    isLystroPage={isLystroPage}
                    closeModal={() => this.closeModal('openPlatformInfoModal')}
                  />
                )}
              </>
              <Dropdown.Item
                className='NavbarDpBtn-link allowed-without-license'
                onClick={this.toLogout}
              >
                {t('user.logout')}
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const UserOptMenuWithTranslation = withRouter(
  withTranslation('translation')(UserOptMenu),
);

export { ChangePasswordModal, UserOptMenuWithTranslation };
